import React, {useEffect, useState} from 'react';
import './FeedbackForm.css'
import ReactInput from "input-format/react";
import { templateParser, templateFormatter, parseDigit } from 'input-format'
const TEMPLATE = '+x (xxx) xxx-xx-xx'
const parse = templateParser(TEMPLATE, parseDigit)
const format = templateFormatter(TEMPLATE)

const FeedbackForm = () => {
    const [phone, setPhone] = useState('')
    const [sended, setsSended] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [error, setError] = useState(false)
    const [textBtn, setTextBtn] = useState('Отправить заявку')

    const generateRandomId = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 5; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    };
    const sendHandler = ()=>{
        const pseudoLeadId = generateRandomId();

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "UNIVERSAL_EVENT",
            eventName: "join_the_team_submit_form",
            pseudo_lead_id: pseudoLeadId 
        });
        /*
        if(!sended){
            if ( document.getElementById('FeedbackForm__nameInput').value === '' || phone === ''){
                setEmpty(true)
                setPhone('')
                document.getElementById('FeedbackForm__nameInput').value = ''
                setTimeout(()=>{
                    setEmpty(false)
                },2000)
            }else{
                fetch('https://macik.beauty/api/mailer.php?name=' + document.getElementById('FeedbackForm__nameInput').value + '&phone=' + phone + '&action=job' ,
                    {
                        headers: {
                            'Accept': 'application/x-www-form-urlencoded;charset=UTF-8'
                        }
                    })
                    .then(response => response.text())
                    .then((val)=>{
                        if(val === 'ok'){
                            setsSended(true)
                        }else {
                            setError(true)
                            setTimeout(()=>{
                                setError(false)
                            },3000)
                        }
                    })
            }
        }
    }
        */
    if (!sended) {
        const name = document.getElementById('FeedbackForm__nameInput').value;
        if (name === '' || phone === '') {
            setEmpty(true);
            setPhone('');
            document.getElementById('FeedbackForm__nameInput').value = '';
            setTimeout(() => {
                setEmpty(false);
            }, 2000);
        } else {

            // Отправляем данные в Telegram
            const botToken = '7613750109:AAHE-mu2OstHtpo5GNn3zFYmV3oK0XGG7ZU'; // токен
            const chatId = '-1002404053640'; // chat_id
            const message = `Новая заявка:\nИмя: ${name}\nТелефон: +${phone} \nВступи в команду`;

            fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chat_id: chatId,
                    text: message,
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.ok) {
                    setsSended(true); 
                } else {
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                    }, 3000);
                }
            })
            .catch(() => {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 3000);
            });
        }
    }
};

    useEffect(() => {
        if(sended)setTextBtn('Заявка отправлена!')
        if(error) setTextBtn('Упс! Что то пошло не так..')
        if(empty) setTextBtn('Поля не заполнены!')
        if(!empty && !error && !sended) setTextBtn('Отправить заявку');
    }, [empty,error,sended]);

    useEffect(() => {
        if (phone.length === 1 && phone !== '7'){
            setPhone( '7' + phone)
        }
    }, [phone]);

    useEffect(() => {
        // Отправка события dataLayer при видимости формы на 50%
        const formViewed = sessionStorage.getItem('formViewed');
        
        if (!formViewed) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
                            // Отправка dataLayer события
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                event: "UNIVERSAL_EVENT",
                                eventName: "join_the_team_show_form",
                                url: "https://macik.beauty/"                             
                            });

                            // Устанавливаем флаг в sessionStorage
                            sessionStorage.setItem('formViewed', 'true');

                            // Отключаем наблюдатель после первого срабатывания
                            observer.unobserve(entry.target);
                        }
                    });
                },
                {
                    threshold: 0.5, // Срабатывает, когда 50% элемента видны
                }
            );

            const target = document.querySelector('.FeedbackForm');
            if (target) observer.observe(target);

            return () => {
                if (target) observer.unobserve(target);
            };
        }
    }, []);

    return (
        <div className={'FeedbackForm'}>
            <span className={'FeedbackForm__background'}></span>
            <div className={'FeedbackForm__contentBox'}>
                <div className={'FeedbackForm__title'}>
                    <span className={'FeedbackForm__heart'}></span>
                    <p>Вступай в нашу <br/> команду</p>
                </div>
                <div className={'FeedbackForm__form'}>
                    <input id={'FeedbackForm__nameInput'} maxLength={80} placeholder={'Имя'} type="text"/>
                    <ReactInput
                        value={phone}
                        onChange={setPhone}
                        parse={parse}
                        format={format}
                        placeholder={'Телефон'}
                        inputMode={'tel'}
                    />
                    <button onClick={sendHandler}>{textBtn}</button>
                </div>
            </div>
        </div>
    );
};

export default FeedbackForm;