import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";
import "./OtherMasters.css";

const OtherMasters = ({ masters }) => {
  const { id } = useParams(); 

  const filteredMasters = masters.filter(
    (master) => master.master === true && master.id !== Number(id)
  );

  const redirect_master = (masterId) => {
    window.location.href = `/master/${masterId}`;
  };

  return (
    <div className="Slider">
      <Splide
        className="Slider__splide"
        hasTrack={false}
        options={{
          width: '100%',
          pagination: false,
          autoWidth: true,
          gap: '20px',
          focus: 'start',
          rewind: false,
          omitEnd: true,
        }}
      >
        <div className="Slider__title_v2">
          <p className="Slider__title__text">Другие мастера</p>
          <div className="Slider__title__arrow splide__arrows">
            <button className="splide__arrow splide__arrow--prev"></button>
            <button className="splide__arrow splide__arrow--next"></button>
          </div>
        </div>

        <SplideTrack>
          {filteredMasters.map((value, index) => (
            <SplideSlide onClick={() => redirect_master(value.id)} key={'Slider__card_v2__' + index}>
              <div className={'Slider__card_v2'}>
                <img className={'Slider__card__img_v2'} src={value.img_url} alt={value.title} />
                <h3 className={'Slider__card__title_v2'}>{value.title}</h3>
                <p className={'Slider__card__description_v2'}>{value.position}</p>
{/* 
                <p className={'Slider__card__experience_v2'}>Опыт работы {value.experience} </p>
*/}
                <button
                  className={'Slider__card__button_v2'}
                  onClick={(e) => {
                    e.stopPropagation();
                    redirect_master(value.id);
                  }}
                >
                  Записаться
                </button>
              </div>
            </SplideSlide>
          ))}
        </SplideTrack>
      </Splide>
    </div>
  );
};

export default OtherMasters;

