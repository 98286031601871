import React, {useEffect, useState} from 'react';
import './Footer.css'
import { Link as RouterLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import content from '../../content.json'
import ReactInput from "input-format/react";
import { templateParser, templateFormatter, parseDigit } from 'input-format'
import { useLocation, useNavigate } from 'react-router-dom';

const TEMPLATE = '+x (xxx) xxx-xx-xx'
const parse = templateParser(TEMPLATE, parseDigit)
const format = templateFormatter(TEMPLATE)

const Footer = () => {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname !== '/') {
            const hash = location.hash;
            if (hash) {
                navigate('/' + hash, { replace: true });
            }
        }
    }, [location, navigate]);

    const [phone, setPhone] = useState('')
    const [sended, setsSended] = useState(false)

    const sendHandler = ()=>{
        setPhone('')
        setsSended(true)
    }

    useEffect(() => {
        if (phone.length === 1 && phone !== '7'){
            setPhone( '7' + phone)
        }
    }, [phone]);

    return (
        <div className={'Footer'}>
            <div className={'Footer__contentBox'}>
                <div className='Footer__top'>
                <div className="Footer__logo">
                    <span className={'Footer__nav__logo'}></span>
                    <div>
                    <a className={'Footer__contacts__phone'} style={{textDecoration: 'none', color: 'white'}}
                           href={"tel:" + content.phone}><p>{content.phone}</p></a>
                        <p className='Footer__contacts__time'>{content.time_day + " " + content.time_in + " - " + content.time_out}</p>
                        </div>
                </div>
                </div>
                <div className='Footer__bottom'>
                <div className="Footer__nav">
                    <Link activeClass="active" className="Footer__nav__link" to="/#about" spy={true} smooth={true} duration={300} >О нас</Link>
                    <Link activeClass="active" className="Footer__nav__link" to="/#services" spy={true} smooth={true} duration={300} >Услуги</Link>
                    <Link activeClass="active" className="Footer__nav__link" to="/#masters" spy={true} smooth={true} duration={300} >Мастера</Link>
                    <Link activeClass="active" className="Footer__nav__link" to="/#contacts" spy={true} smooth={true} duration={300} >Контакты</Link>
                </div>
                <div className="Footer__form">
                    <p>Остались вопросы? Оставьте свой номер и наш 
                    менеджер свяжется с вами через 5 минут</p>
                    {/*<ReactInput*/}
                    {/*    value={phone}*/}
                    {/*    onChange={setPhone}*/}
                    {/*    parse={parse}*/}
                    {/*    format={format}*/}
                    {/*    placeholder={'Телефон'}*/}
                    {/*    inputMode={'tel'}*/}
                    {/*/>*/}
                    {/*<button onClick={sendHandler}>{(sended)? 'Упс! Что то пошло не так..' : 'Оставить заявку'}</button>*/}
                    <button onClick={()=>{
                         window.dataLayer = window.dataLayer || [];
                         window.dataLayer.push({
                            event: "UNIVERSAL_EVENT",
                            eventName: "request_click_button",
                            url: "https://macik.beauty/",
                         });
                        window.location = 'https://n1142833.yclients.com'}} >Оставить заявку</button>
                </div>
                <div className="Footer__contacts">
                    <div>
                        
                    </div>
                    <p>{content.address}</p>
                    <a href={"mailto:" + content.email_2} style={{textDecoration: 'none', color: 'white'}}>
                        <p>{content.email_2}</p>
                    </a>
                    <a className={'Footer__downloadLink'} href="https://macik.beauty/file/Privacy_policy.docx">Политика
                        в отношении обработки <br/> персональных данных </a>
                    <a className={'Footer__downloadLink'} href="assets/files/offer.pdf" download>Оферта об оказании услуг </a>
                </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;