import React from "react";
import "./PortfolioSlider.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import content from "../../content.json";

function shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

const PortfolioSlider = () => {
  const allPhotos = content.portfolio.cards.map((card) => card.img_url);

  const photosTop = allPhotos.slice(0, 6);
  const photosBottom = allPhotos.slice(6, 12);
  return (
    <div className={"portfolioSlider"}>
      <NormalSpeedSlider photos={shuffle(photosTop)} />
      <ReverseSpeedSlider photos={shuffle(photosBottom)} />
    </div>
  );
};

const NormalSpeedSlider = ({ photos }) => (
  <Splide
    options={{
      width: "100%",
      arrows: false,
      pagination: false,
      autoWidth: true,
      gap: "20px",
      type: "loop",
      focus: "center",
      autoScroll: {
        pauseOnHover: false,
        pauseOnFocus: false,
        rewind: false,
        speed: 0.5,
      },
    }}
    extensions={{ AutoScroll }}
  >
    {photos.map((url, index) => (
      <SplideSlide key={index}>
        <div className={"slideImage"}>
          <img src={url} alt={`Portfolio ${index}`} loading="lazy" />
        </div>
      </SplideSlide>
    ))}
  </Splide>
);

const ReverseSpeedSlider = ({ photos }) => (
  <Splide
    options={{
      width: "100%",
      arrows: false,
      pagination: false,
      autoWidth: true,
      gap: "20px",
      type: "loop",
      focus: "center",
      autoScroll: {
        pauseOnHover: false,
        pauseOnFocus: false,
        rewind: false,
        speed: -0.5,
      },
    }}
    extensions={{ AutoScroll }}
  >
    {photos.map((url, index) => (
      <SplideSlide key={index}>
        <div className={"slideImage"}>
          <img src={url} alt={`Portfolio ${index}`} />
        </div>
      </SplideSlide>
    ))}
  </Splide>
);

export default PortfolioSlider;
